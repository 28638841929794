import { createContext, useContext, useEffect, useState } from 'react'
import storage from 'utils/Storage'

const CardsContext = createContext({})

export const useCards = () => {
  const context = useContext(CardsContext)

  if (!context) {
    throw new Error(
      `useCards hook must be used within a 'CardsProvider' component`
    )
  }

  return context
}

export const CardsProvider = ({ children }) => {
  const [selectedCard, setSelectedCard] = useState(() => {
    const storagedInfo = storage.session.getItem('selected_card_data')

    return storagedInfo ? storagedInfo.card : ''
  })
  const [address, setAddress] = useState(() => {
    const storagedInfo = storage.session.getItem('selected_card_data')

    return storagedInfo ? storagedInfo.address : ''
  })
  const [selectedCardInfo, setSelectedCardInfo] = useState()

  const cards = {
    'rvbr-card': {
      image: '/images/rvbr-card-vertical.png',
      title: 'Cartão RVBR',
      details: [
        <>Limite pré aprovado de R$ 250,00</>,
        'Anuidade grátis',
        'Pagamento por aproximação',
      ],
    },
    'rvbr-cashback': {
      image: '/images/rvbr-cashback-vertical.png',
      title: 'Cartão RVBR Cashback',
      details: [
        'Cashback de 1%',
        'R$ 100,00 de crédito para compras',
        <>Rendimento maior que poupança com a conta digital</>,
        'Anuidade grátis',
      ],
    },
    'rvbr-pass': {
      image: '/images/rvbr-pass-vertical.png',
      title: 'Cartão RVBR Pass Black',
      details: [
        'Renda mínima R$ 10.000,00',
        <>Anuidade grátis gastando R$ 2.000,00 por mês</>,
        'Pontuação 1US$ = 2,2 pts',
      ],
    },
  }

  useEffect(() => {
    const userCardData = {
      card: selectedCard,
      address,
    }

    if (selectedCard) {
      setSelectedCardInfo(cards[selectedCard])
    }

    storage.session.setItem('selected_card_data', userCardData)
  }, [selectedCard, address])

  return (
    <CardsContext.Provider
      value={{
        selectedCard,
        setSelectedCard,
        address,
        setAddress,
        selectedCardInfo,
      }}
    >
      {children}
    </CardsContext.Provider>
  )
}
